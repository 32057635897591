import { useCallback, useMemo } from "react";
import cn from "classnames";
import { Flex, Img, VideoPlayIcon } from "@components";
import { ProductImage } from "@components/products";
import { PRODUCT_TYPES } from '@constants';
import { ThumbnailsProps } from "@ts/index";
import styles from './Carousel.module.scss';

const Thumbnails = ({
	hideThumbnail,
	secondary,
	selectedImgIndex,
	setSelectedImgIndex,
	isThumbsClicked,
	setIsThumbsClicked,
	images,
	type,
	withThreeFourthImage,
	video,
	copyTopFrameVariant,
	imageLoadingHandler,
	name,
}: ThumbnailsProps) => {
	let productType;
	const handleThumbnailChange = useCallback(
		index => {
			setSelectedImgIndex(index);
			!isThumbsClicked && setIsThumbsClicked(!isThumbsClicked);
		},
		[isThumbsClicked, setIsThumbsClicked, setSelectedImgIndex]
	);

	if (type.includes(PRODUCT_TYPES.TOP_FRAME)) {
		productType = 'Top Frame'
	} else if (type.includes(PRODUCT_TYPES.BASE_FRAME)) {
		productType = 'Base Frame'
	} else if (type === PRODUCT_TYPES.GIFT_CARD) {
		productType = '';
	} else {
		productType = type;
	}

	const videoThumbnail = useMemo(() => video && (
		<Flex
			align='center'
			className={cn(styles['thumbnail-image'], {
				[styles['active-thumbnail']]: selectedImgIndex === copyTopFrameVariant.length,
				[styles['video-thumbnail']]: !!video?.sources?.length,
			})}
			onClick={() => handleThumbnailChange(copyTopFrameVariant.length)}
			key={`thumbnail-${copyTopFrameVariant.length}`}
			thumbnail-index={`${withThreeFourthImage ? 4 : 3}-3/4-image=${withThreeFourthImage}`}
		>
			<div data-product-type={type}>
				<div className={styles['play-icon']}>
					<VideoPlayIcon />
				</div>
				<Img
					src={video?.previewImage?.url}
					alt={`${productType} ${name} video thumbnail 5`}
					sizes='(max-width: 55em) 24w, (min-width: 551px) 64w, (min-width: 76.8em) 1000w'
					loading='eager'
				/>
			</div>
		</Flex>
	), [copyTopFrameVariant.length, handleThumbnailChange, selectedImgIndex, type, video, withThreeFourthImage, name, productType]);

	const renderThumbnails = useCallback(() => {
		const thumbnailImgs =
			images.length > 0
				? images.map(({ url }, index) => (
					<Flex
						align='center'
						className={cn(styles['thumbnail-image'], {
							[styles['active-thumbnail']]: selectedImgIndex === index,
						})}
						onClick={() => handleThumbnailChange(index)}
						key={`thumbnail-${index}`}
						thumbnail-index={index}
					>
						<div data-product-type={type}>
							<Img
								src={url}
								alt={`${productType} ${name} thumbnail ${index + 1}`}
								sizes='(max-width: 55em) 24w, (min-width: 551px) 64w, (min-width: 76.8em) 1000w'
								loading='eager'
							/>
						</div>
					</Flex>
				))
				: [];

		return (
			<Flex column className={styles['thumbnail-container']}>
				{thumbnailImgs.concat(videoThumbnail)}
			</Flex>
		);
	}, [handleThumbnailChange, images, selectedImgIndex, type, videoThumbnail, name, productType]);

	const renderTopFrameThumbnails = useCallback(() => {
		const topFrameThumbnailImgs =
			copyTopFrameVariant.length > 0
				? copyTopFrameVariant.map(({ primary, secondary }, index) => (
					<Flex
						align='center'
						className={cn(styles['thumbnail-image'], {
							[styles['active-thumbnail']]: selectedImgIndex === index,
						})}
						onClick={() => handleThumbnailChange(index)}
						key={`thumbnail-${index}`}
						thumbnail-index={`${index}-3/4-image=${withThreeFourthImage}`}
					>
						<ProductImage
							primary={primary}
							secondary={secondary}
							name={name}
							type={type}
							imageLoadingHandler={imageLoadingHandler}
							thumbnails={true}
							sizes='200px'
						/>
					</Flex>
				))
				: [];

		return (
			<Flex column className={styles['thumbnail-container-tops']}>
				{topFrameThumbnailImgs.concat(videoThumbnail)}
			</Flex>
		);
	}, [copyTopFrameVariant, handleThumbnailChange, imageLoadingHandler, name, selectedImgIndex, type, videoThumbnail, withThreeFourthImage]);

	return !hideThumbnail && (secondary ? renderTopFrameThumbnails() : renderThumbnails())
};

export default Thumbnails;
