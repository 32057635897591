import { BADGE_TYPES } from "@constants"
import Lozenge from "../Lozenge"

const Tags = ({ tags }: { tags: string[] }) => {
	return tags?.includes(BADGE_TYPES.BEST_SELLER.name) ? (
		<Lozenge
			backgroundColor={BADGE_TYPES.BEST_SELLER.backgroundColor}
			color={BADGE_TYPES.BEST_SELLER.color}
			shape='square'
			alignment='left'
		>
			{BADGE_TYPES.BEST_SELLER.displayText}
		</Lozenge>
	) : null
}

export default Tags
