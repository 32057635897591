import { memo, useState, useEffect } from 'react';
import Head from 'next/head';
import { m } from 'framer-motion';
import { CarouselStyles as styles, Img, Loading } from '@components';
import { ProductImageProps } from '@ts/index';

const sizesDefault = `(min-width: 1440px) 1200px, (min-width: 1024px) 992px,
(min-width: 769px) 769px,
(min-width: 550px) 551px,
(min-width: 376px) 375px, 
200px`;

const ProductImage = ({
	imageLoadingHandler,
	name,
	thumbnails,
	type,
	loading = 'lazy',
	primary: primarySrc,
	secondary: secondarySrc = null,
	sizes = undefined,
	primaryAnimation,
	secondaryAnimation,
}: ProductImageProps) => {
	const [isPrimaryLoaded, setIsPrimaryLoaded] = useState(false);
	const [isSecondaryLoaded, setIsSecondaryLoaded] = useState(false);
	const [primaryKey, setPrimaryKey] = useState(Math.random());
	const [secondaryKey, setSecondaryKey] = useState(Math.random());
	const primaryImageProps = {
		onLoad: () => imageLoadingHandler && imageLoadingHandler(false),
		alt: `Top Frame ${name}`,
		sizes: sizes || sizesDefault,
		loading,
		className: styles['primary-image'],
		src: primarySrc,
		width: 2400,
		height: 1200,
	}

	const secondaryImageProps = {
		...primaryImageProps,
		alt: `Base Frame`,
		className: styles['secondary-image'],
		src: secondarySrc,
		width: 2400,
		height: 1200,
	};

	useEffect(() => {
		setPrimaryKey(Math.random());
		setIsPrimaryLoaded(false);
	}, [primaryAnimation, primarySrc]);

	useEffect(() => {
		setSecondaryKey(Math.random());
		setIsPrimaryLoaded(false);
	}, [secondaryAnimation, secondarySrc]);

	return (
		<>
			<Head>
				<>
					<link rel='preload' as='image' href={primarySrc} />
					{secondarySrc && <link rel='preload' as='image' href={secondarySrc} />}
				</>
			</Head>
			<div className={thumbnails ? '' : styles['image-container']} data-testid='product-image' data-product-type={type} data-product-name={name}>
				{primarySrc && (
					<>
						{!!primaryAnimation ? (
							<>
								<m.img
									key={primaryKey} // This key allows animation to rerun on rebuild
									{...primaryAnimation}
									{...primaryImageProps}
									onLoad={() => {
										setIsPrimaryLoaded(true);
									}}
								/>
								{!isPrimaryLoaded && <Loading />}
							</>
						) : <Img {...primaryImageProps} />}
					</>
				)}
				{secondarySrc && (
					<>
						{!!secondaryAnimation ? (
							<>
								<m.img
									key={secondaryKey} // This key allows animation to rerun on rebuild
									{...secondaryAnimation}
									{...secondaryImageProps}
									onLoad={() => {
										setIsSecondaryLoaded(true);
									}}
								/>
								{!isSecondaryLoaded && <Loading />}
							</>
						) : <Img {...secondaryImageProps} />}
					</>
				)}
			</div>
		</>
	);
};

export default memo(ProductImage);
