import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const Truck = () => {
	return (
		<AccessibleIcon.Root label='Truck Icon'>
			<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M15.5864 15.5862H4V4H15.5864V15.5862Z' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
				<path d='M8.96558 18.069C8.96558 19.1355 8.10103 20 7.03455 20C5.96807 20 5.10352 19.1355 5.10352 18.069C5.10352 17.0025 5.96807 16.1379 7.03455 16.1379C8.10103 16.1379 8.96558 17.0025 8.96558 18.069Z' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
				<path d='M18.8962 18.069C18.8962 19.1355 18.0317 20 16.9652 20C15.8987 20 15.0342 19.1355 15.0342 18.069C15.0342 17.0025 15.8987 16.1379 16.9652 16.1379C18.0317 16.1379 18.8962 17.0025 18.8962 18.069Z' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
				<path d='M20 15.5862H15.5859V8.41382H16.6896C18.5179 8.41382 20 9.89586 20 11.7242V15.5862Z' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
			</svg>
		</AccessibleIcon.Root>
	)
}

export default Truck;
