import { Video } from "@components";
import styles from './Carousel.module.scss';

const VideoSlide = ({ video, type, name }) => {
	return video && (
		<div className={styles['video-container']} data-product-type={type} data-product-name={name}>
			<Video video={video} />
		</div>
	)
}

export default VideoSlide;
