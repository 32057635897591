import * as AccessibleIcon from '@radix-ui/react-accessible-icon';

const Shield = () => {
	return (
		<AccessibleIcon.Root label='Shield'>
			<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M12 3.00171L4.02058 6.49425C4.02058 6.49425 3.19512 21.0017 12 21.0017C20.8049 21.0017 19.9794 6.49425 19.9794 6.49425L12 3.00171Z' stroke='black' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
				<path d='M10 11.9036H14M12 14.0017L12 10.0017' stroke='black' strokeWidth='2' strokeLinecap='square'/>
			</svg>
		</AccessibleIcon.Root>
	);
};

export default Shield;
