import { MutableRefObject } from "react";
import { Image } from "@ts/shopify-storefront-api";
import { Chevron } from "@components";
import styles from './Carousel.module.scss';

type NavButtonsProps = {
	images: Image[];
	type: string;
	prevElRef: MutableRefObject<HTMLButtonElement>;
	nextElRef: MutableRefObject<HTMLButtonElement>;
	handleNavigation: (action: string) => void;
}

const NavButtons = ({
	images,
	type,
	prevElRef,
	nextElRef,
	handleNavigation,
}: NavButtonsProps) => {
	return (images.length > 1 || type.includes('TOP_FRAME')) && (
		<>
			<button
				ref={prevElRef}
				className={styles['swiper-button-prev']}
				onClick={() => handleNavigation('prev')}
				aria-label='previous slide'
				data-previous-slide-button={type}
			>
				<Chevron direction='left' />
			</button>
			<button
				ref={nextElRef}
				className={styles['swiper-button-next']}
				onClick={() => handleNavigation('next')}
				aria-label='next slide'
				data-next-slide-button={type}
			>
				<Chevron direction='right' />
			</button>
		</>
	)
}

export default NavButtons;
