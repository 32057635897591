import Head from "next/head";
import { ProductImage } from "@components/products";
import { Img } from "@components";
import styles from './Carousel.module.scss';
import 'swiper/css';

type SlideProps = {
    secondary?: string;
	type: string;
	name: string;
	imageLoadingHandler?: (x: boolean) => void;
	index: number;
	url?: string;
	width?: number;
	height?: number;
	primary?: string;
};

const Slide = ({
	imageLoadingHandler,
	name,
	type,
	primary,
	secondary,
	index,
	url,
	width,
	height,
}: SlideProps) => {
	const sizes = `(min-width: 1024px) 1440px,
							(min-width: 769px) 1200px,
							(min-width: 376px) 769px, 
							551px`;

	return secondary ? 
		<ProductImage
			primary={primary}
			secondary={secondary}
			name={name}
			type={type}
			imageLoadingHandler={imageLoadingHandler}
			loading={index === 0 ? 'eager' : 'lazy'}
			// 2nd and 3rd Image are Zoomed, thus higher quality images should be loaded
			{...(index !== 0 && {
				sizes,
			})}
		/> : 
		<>
			<Head>
				{index === 0 && (
					<>
						<link rel='preload' as='image' href={url} />
					</>
				)}
			</Head>
			<div
				className={styles['image-container']}
				data-product-type={type}
				data-product-name={name}
			>
				<Img
					onLoad={() => imageLoadingHandler && imageLoadingHandler(false)}
					src={url}
					alt={`slide image ${index + 1}`}
					width={width / 2}
					height={height / 2}
					sizes='(max-width: 55em) 24w, (min-width: 551px) 64w, (min-width: 76.8em) 1000w'
					loading={index === 0 ? 'eager' : 'lazy'}
				/>
			</div>
		</>
};

export default Slide;
